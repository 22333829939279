import { CheckCircleFilled } from '@ant-design/icons';
import { Input, UploadFile } from 'antd';
import { Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import AWS from 'aws-sdk';
import _ from 'lodash';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { Loading } from '../../../components/common/Loading';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import BaseForm from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import { CANCEL, SUCCESS } from '../../../constants/MessageConstants';
import { pathCrop1, pathCrop2, pathCrop3, pathMedicine } from '../../../constants/SvgIcon';
import { useAppDispatch } from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import CropImageForm from '../../../module/UploadImage/CropImageForm';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { ImageFileBeingExamCreateCustomDto } from '../../../types/ImageFileBeingExam/ImageFileBeingExamCreate';
import { InformationBeingExam } from '../../../types/PatientRecord/PatientFileRecord';
import { InfoFileUploadCombo_v2, InfoFileUploadCombo_v2Update } from '../../../types/UploadFile/InfoFileUpload';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { clinicAction } from '../../Infomation/ClinicSlice';
import { MedicalExamPictureCombo, UpdateImageFileBeingExamAllV2 } from '../api/contants';
import WebcamCapture from './WebCameView';

interface IProps
{
    informationBeingExam: InformationBeingExam | undefined;
    imageFileBeingExamCreate: InfoFileUploadCombo_v2[] | null;
    loadImageProcess?: () => Promise<void>;
    setSelectFlow: React.Dispatch<React.SetStateAction<number>>
    onChangeProcessBar: (value: string, oldValue: string) => void;
    onloadInfoBeingExam: () => Promise<void>;
    imageArrCurrent: React.MutableRefObject<InfoFileUploadCombo_v2[]>;
    showConfirm: () => void;
    clinicId: number;
    steps?: string[]
    updateNextFlow: any
}

interface IImageAdjustment 
{
    brightness: number,
    contrast: number,
    hue: number;
    saturation: number;
}

interface IState
{
    devicesCombo: IComboBox[];
    deviceId: string | null;
    infoClinic: Clinic;
}
interface IParts
{
    ETag?: string,
    PartNumber?: number,
    clinicId?: number,
}

const UploadImageView_V2 = (props: IProps) =>
{
    const dispatch = useAppDispatch();
    const { clinicId, steps = [], updateNextFlow } = props;
    const overlayRef = useRef<OverlayRef>(null);
    const webcamRef = useRef<any>(null);
    const [defaultFileList, setDefaultFileList] = useState<UploadFile<any>[]>([]);
    const [imageAdjustment, setImageAdjustment] = useState<IImageAdjustment>({ brightness: 100, contrast: 100, hue: 0, saturation: 100 });
    const modalRef = useRef<ModalRef>(null);

    const { informationBeingExam, showConfirm, loadImageProcess, setSelectFlow, onChangeProcessBar, imageFileBeingExamCreate, onloadInfoBeingExam, imageArrCurrent } = props;
    const [comboActive, setComboActive] = useState<InfoFileUploadCombo_v2[]>(imageFileBeingExamCreate as InfoFileUploadCombo_v2[]);


    const [optionsMedicalMedicalPicture, setOptionsMedicalPicture] = useState<IComboBox[]>([]);
    const iconMedicine: React.ReactNode = <svg fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 14">
        <path fillRule="evenodd"
            clipRule="evenodd"
            d={pathMedicine}
            fill="#fff" />
    </svg>;


    const iconCrop: React.ReactNode = <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        viewBox="0 0 512 512">
        <path d={pathCrop1} />
        <path d={pathCrop2} />
        <path d={pathCrop3} />
    </svg>;

    const [state, setState] = useMergeState<IState>({
        devicesCombo: [],
        deviceId: '',
        infoClinic: {},
    });
    const { infoClinic } = state;
    const comboActiveRef = useRef<InfoFileUploadCombo_v2[]>(imageFileBeingExamCreate as InfoFileUploadCombo_v2[]);

    //Upload File Cloud
    const bucketName = process.env.REACT_APP_BUCKET_NAME || 'vnmedic-dev';

    const config = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY || '',
        secretAccessKey: process.env.REACT_APP_SECRET_KEY || '',
        s3ForcePathStyle: true,
        endpoint: process.env.REACT_APP_AWS_S3_SERVICE_URL || 'https://store-api.vnmedic.vn',
        bucketName: bucketName,
        signatureVersion: 's3',
        sslEnable: false,
        httpOptions: {
            timeout: 1000 * 60000
        }
    };
    const s3 = new AWS.S3(config);

    const partSize = 1024 * 1024 * 10;
    let objectKey = '';

    //
    useEffect(() =>
    {
        {
            loadOptionsMedicalExamPicture();
            loadDevices();
        }
    }, []);



    useEffect(() =>
    {
        {
            comboActiveRef.current = imageFileBeingExamCreate as InfoFileUploadCombo_v2[];
            imageArrCurrent.current = imageFileBeingExamCreate as InfoFileUploadCombo_v2[];
            setComboActive(imageFileBeingExamCreate as InfoFileUploadCombo_v2[]);
        }
    }, [imageFileBeingExamCreate]);

    const getObjectType = (fileType: string) =>
    {
        switch (fileType)
        {
            case 'image/jpeg': return 'image';
            case 'image/png': return 'image';
            case 'video/mp4': return 'video';
            case 'application/pdf': return 'pdf';
            default: return 'other';
        }
    };
    //Upload Cloud
    const createMultiPartUpload = async (file: RcFile, fileName: string, objectKey: string) =>
    {
        const params = {
            Key: objectKey,
            ContentType: file.type,
            ACL: 'public-read',
            Bucket: bucketName
        };
        s3.createMultipartUpload(params, (mpErr, multipart) =>
        {
            if (mpErr)
            {
                console.log('Error', mpErr);
            }
            uploadPart(multipart, 1, 0, file);
        });

    };
    const uploadPart = (multipart: any, partnum: any, rangeStart: number, file: RcFile) =>
    {
        const reader = new FileReader();


        if (file.size <= rangeStart)
        {
            getETag(multipart.UploadId, partnum - 1);
            return;
        }
        reader.readAsArrayBuffer(file.slice(rangeStart, rangeStart + partSize));
        reader.onload = () =>
        {
            let byte2Upload = null;
            byte2Upload = reader.result;
            const partParams: AWS.S3.UploadPartRequest = {
                Body: byte2Upload as AWS.S3.Body,
                Bucket: bucketName,
                Key: objectKey,
                PartNumber: partnum,
                UploadId: multipart.UploadId
            };
            s3.uploadPart(partParams, (multiErr, mData) =>
            {
                if (multiErr)
                {
                    return;
                }
                byte2Upload = null;
                uploadPart(multipart, partnum + 1, rangeStart + partSize, file);
            }).on('httpUploadProgress', function (evt)
            {
                const percent = (evt.loaded * 100) / (evt.total ? evt.total : 100);

                if (percent === 100)
                {
                    comboActiveRef.current?.map(item =>
                    {
                        if (item.file?.name === file.name)
                        {
                            getSignedURLPromise(item?.nameImage as string, item?.objectKey as string);
                        }
                    });
                }
            });

        };
    };
    const getETag = (uploadId: any, totalPart: any) =>
    {
        const listPartParams = {
            Bucket: bucketName,
            Key: objectKey,
            UploadId: uploadId,
            MaxParts: totalPart,
            PartNumberMarker: 0
        };
        s3.listParts(listPartParams, (err, data) =>
        {
            if (err)
            {
                console.log('err', err);
                return;
            }
            const multipartMap = {
                Parts: ([0] as IParts[])
            };
            if (data != undefined)
            {
                for (let i = 0; i < (data?.Parts?.length as number); i++)
                {
                    const aPart = data?.Parts?.[i];
                    multipartMap.Parts[i] = {
                        ETag: aPart?.ETag,
                        PartNumber: aPart?.PartNumber as number
                    };
                }
            }
            const doneParams = {
                Bucket: bucketName,
                Key: objectKey,
                MultipartUpload: multipartMap,
                UploadId: uploadId
            };
            completeMultiPartUpload(doneParams);
        });
    };

    const completeMultiPartUpload = (doneParams: any) =>
    {
        s3.completeMultipartUpload(doneParams, (err, data) =>
        {
            if (err)
            {
                console.log('check Err', err);
            }
        });
    };
    const getSignedURLPromise = (nameImage: string, objectKey: string) =>
    {
        const params = { Bucket: bucketName, Key: objectKey, Expires: 10000000 };
        const promise = s3.getSignedUrlPromise('getObject', params);
        promise.then((url) =>
        {
            const thumbUrlArr = comboActiveRef.current?.map(item =>
            {
                if (item.nameImage === nameImage)
                {
                    item.thumbUrlCloud = url;
                }
                if ((item.fileType ?? '*') in FileType.Pdf)
                {
                    item.thumbUrlCloud = pdfIcon;
                }
                return item;
            });
            comboActiveRef.current = thumbUrlArr;
            imageArrCurrent.current = thumbUrlArr as InfoFileUploadCombo_v2[];
            overlayRef.current?.close();

        }, function (err) { console.log(err); });

    };

    // Camera

    const loadDevices = async () =>
    {
        const constraints = { video: true, audio: false };
        const stream = await navigator.mediaDevices.getUserMedia(constraints); // ask for permission
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const cameraDevices: MediaDeviceInfo[] = [];
        for (let i = 0; i < mediaDevices.length; i++)
        {
            const device = mediaDevices[i];
            if (device.kind == 'videoinput')
            { // filter out audio devices
                cameraDevices.push(device);
            }
        }
        const tracks = stream.getTracks();
        for (let i = 0; i < tracks.length; i++)
        {
            const track = tracks[i];
            track.stop(); // stop the opened camera
        }
        const comboArr: IComboBox[] = [];
        cameraDevices?.map(item =>
        {
            const valueArr: IComboBox = {
                label: item.label,
                value: item.deviceId
            };
            comboArr.push(valueArr);
        });
        setState({
            ...state,
            devicesCombo: comboArr,
            deviceId: cameraDevices?.[0] ? cameraDevices[0].deviceId : null
        });

    };
    const onSetDeviceCamera = (value: string) =>
    {
        // overlayRef.current?.open();
        setState({
            ...state,
            deviceId: value
        });
        // overlayRef.current?.close();
    };


    const loadOptionsMedicalExamPicture = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + MedicalExamPictureCombo, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as IComboBox[];
            setOptionsMedicalPicture(data);
        }
    };

    const onChangeTick = (index: number) =>
    {
        const comboActiveNew = _.cloneDeep(comboActive).map(item =>
        {
            if (item.index === index)
            {
                item.isTick = !item.isTick;
            }
            return item;
        });
        comboActiveRef.current = comboActiveNew as InfoFileUploadCombo_v2[];
        imageArrCurrent.current = comboActiveNew as InfoFileUploadCombo_v2[];
        setComboActive(comboActiveNew);
    };

    const onSetActive = (index: number, valueCombo: string) =>
    {
        const comboActiveNew = _.cloneDeep(comboActive).map(item =>
        {
            if (item.index === index)
            {
                item.medicalExaminationPicturesId = parseInt(valueCombo);
            }
            return item;
        });
        comboActiveRef.current = comboActiveNew;
        imageArrCurrent.current = comboActiveNew as InfoFileUploadCombo_v2[];

        setComboActive(comboActiveNew);

    };

    const onSetDescription = (index: number, description: string) =>
    {
        const comboActiveNew = _.cloneDeep(comboActive).map(item =>
        {
            if (item.index === index)
            {
                item.description = description;
            }
            return item;
        });

        comboActiveRef.current = comboActiveNew;
        imageArrCurrent.current = comboActiveNew as InfoFileUploadCombo_v2[];

        setComboActive(comboActiveNew);

    };

    const renderUploadCombo = (value: InfoFileUploadCombo_v2, index: number) =>
    {
        const thumbUrlShow = (value.thumbUrlCloud ? value.thumbUrlCloud : value.thumbUrl) as string;
        return <div key={index}
            className='flex flex-col justify-center align-center items-center'
            style={{ marginTop: '10px', width: '50%' }}>
            {
                <div style={{ height: '95%', width: '95%' }} >
                    <div className='rounded-lg shadow-xl'
                        style={{ width: '100%', aspectRatio: 'auto' }}
                        onClick={() =>
                        {
                            if (value.medicalExaminationPicturesId)
                            {
                                onChangeTick(value.index as number);
                            }
                            else
                            {
                                ApiUtil.ToastWarning('Vui lòng chọn tên hình ảnh!');
                            }
                        }}>
                        <div className="flex h-full w-full"

                        >
                            <div className="flex h-full w-full border-2 border-dashed" style={{ backgroundColor: '#F3F5F9', borderRadius: '8px', justifyContent: 'center' }}>
                                <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                                    {FileType.Video.includes(value.fileType ?? '') ?
                                        <video
                                            style={{ height: '100%', width: '100%', zIndex: 1, borderRadius: '8px' }}
                                            controls>
                                            <source
                                                src={thumbUrlShow}
                                            />
                                        </video>
                                        : (FileType.Pdf.includes(value.fileType ?? '')) ?
                                            <img
                                                src={pdfIcon}
                                                style={{ height: '50%', width: '50%', zIndex: 1, borderRadius: '8px' }}
                                                className="bg-white border rounded"
                                                alt="..."
                                            /> :
                                            <img
                                                src={thumbUrlShow}
                                                style={{ height: '100%', width: '100%', zIndex: 1, borderRadius: '8px' }}
                                                className="bg-white border rounded"
                                                alt="..."
                                            />
                                    }

                                    {
                                        value?.isTick ?
                                            <div style={{ height: '100%', width: '100%', position: 'absolute', top: '0' }}>
                                                <div className='flex absolute'
                                                    style={{
                                                        alignItems: 'center'
                                                        , justifyContent: 'center'
                                                        , height: '100%'
                                                        , width: '100%'
                                                        , position: 'absolute'
                                                        , backgroundColor: '#000000'
                                                        , opacity: 0.5
                                                        , zIndex: 2
                                                        , borderRadius: '8px'
                                                    }}>
                                                </div>
                                                <div className='flex absolute'
                                                    style={{
                                                        alignItems: 'center'
                                                        , justifyContent: 'center'
                                                        , height: '100%'
                                                        , width: '100%'
                                                        , position: 'absolute'
                                                        , fontSize: 40
                                                        , color: 'white'
                                                        , zIndex: 3
                                                    }}>
                                                    <CheckCircleFilled />
                                                </div>

                                            </div> : <div></div>
                                    }
                                </div>



                            </div>
                        </div>
                    </div>
                    <div className="mt-4 w-full">
                        <ComboBox noShowLabel={true}
                            noFieldForm={true}
                            value={value.medicalExaminationPicturesId ? value.medicalExaminationPicturesId?.toString() : undefined}
                            onChange={(valueCombo) => { onSetActive(value.index, valueCombo); }}
                            comboValue={optionsMedicalMedicalPicture}
                            isClear={false}
                            name={'combo' + value.index}
                            label={'Chọn tên hình ảnh'} />
                    </div>
                    <div className="mt-4 px-3 w-full">
                        <Input
                            value={value.description || ''}
                            placeholder='Nhập ghi chú'
                            style={{
                                backgroundColor: '#fff',
                                border: '1px solid #d9d9d9',
                                borderRadius: '7px',
                            }}
                            onChange={(e) => onSetDescription(value.index, e.target.value)}
                        />
                    </div>
                </div>

            }

        </div >;
    };



    const onSubmitAll = async () =>
    {
        const imageFileBeingExamCreateDtos = await onLoadImageCloud();
        const params: ImageFileBeingExamCreateCustomDto = {
            medicalExaminationId: informationBeingExam?.medicalExaminationId as number,
            imageFileBeingExamCreateDtos: imageFileBeingExamCreateDtos
        };
        overlayRef.current?.open();
        if (imageFileBeingExamCreateDtos.length === 0)
        {
            overlayRef.current?.close();
            return ApiUtil.ToastError('Vui lòng chọn ít nhất 1 hình ảnh để hoàn tất!');
        }

        // Lấy ra bước hiện tại
        const currentIndex = steps?.indexOf('1');
        const nextStep = steps[currentIndex + 1];

        const response = await AxiosClient.post<ApiResponse<any>>(BASE_API_PATH + UpdateImageFileBeingExamAllV2, params);
        if (response.data.success)
        {
            await updateNextFlow(nextStep);
            if (response.data.results) {
                dispatch(clinicAction.setShowStorageWarning(response.data.results.isSizeWarning || false));
            }
            ApiUtil.ToastSuccess(response.data.message);
            await onloadInfoBeingExam();
        }
        overlayRef.current?.close();

        setSelectFlow(Number(nextStep));
        onChangeProcessBar(nextStep, '1');
        await loadImageProcess?.();
        overlayRef.current?.close();

    };

    const onLoadImageCloud = async () =>
    {
        const imageBeingUploadArr: InfoFileUploadCombo_v2Update[] = [];
        _.cloneDeep(comboActive).map(item =>
        {
            if (item.isTick)
            {
                const imageBeingUpload: InfoFileUploadCombo_v2Update = {
                    name: item.nameImage as string,
                    isIstead: false,
                    process: 100,
                    thumbUrlCloud: item.thumbUrlCloud,
                    medicalExaminationId: informationBeingExam?.medicalExaminationId as number,
                    MedicalExaminationPicturesId: item.medicalExaminationPicturesId,
                    isVideo: item.isVideo ? true : false,
                    fileType: item.fileType,
                    objectKey: item.objectKey,
                    description: item.description
                };
                imageBeingUploadArr.push(imageBeingUpload);
            }

        });
        return imageBeingUploadArr;
    };

    const getImageWithFilter = () =>
    {
        const video = webcamRef.current.video;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        if (!ctx)
        {
            console.error('2D rendering context is not supported.');
            return;
        }
        // Apply the CSS filter style
        ctx.filter = `brightness(${ imageAdjustment.brightness }%) contrast(${ imageAdjustment.contrast }%) hue-rotate(${ imageAdjustment.hue }deg) saturate(${ imageAdjustment.saturation }%)`;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL('image/jpeg');
    };

    const capture = () =>
    {
        const imageSrc = getImageWithFilter();
        if (!imageSrc)
        {
            console.error('Failed to capture image with filter.');
            return;
        }
        const imageBlob = convertBase64ToFile(imageSrc);
        const today = new Date();
        const date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
        const time = today.getHours() + '_' + today.getMinutes() + '_' + today.getSeconds();
        const fileName = informationBeingExam?.medicalExaminationId + '_' + date + '_' + time + '.png';
        const fileType = imageBlob.type;
        objectKey = `clinics/${ clinicId?.toString() ?? '000000' }/${ getObjectType(fileType as string) }/${ fileName }`;
        const imageComboNew: InfoFileUploadCombo_v2 = {
            index: (comboActive?.length + 1) as number,
            isTick: false,
            thumbUrl: imageSrc,
            thumbUrlCloud: '',
            nameImage: fileName,
            objectKey: objectKey,
            file: imageBlob as RcFile,
            fileType: 'image/png'
        };

        comboActiveRef.current = [
            ...comboActive,
            imageComboNew
        ];
        imageArrCurrent.current = [
            ...comboActive,
            imageComboNew
        ];
        createMultiPartUpload(imageBlob as RcFile, fileName as string, objectKey);
        setComboActive([
            ...comboActive,
            imageComboNew
        ]);
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const cropImage = () =>
    {
        const imageSrc = getImageWithFilter();
        if (!imageSrc) 
        {
            console.error('Failed to capture image with filter.');
            return;
        }
        modalRef.current?.onOpen(<CropImageForm onCropImage={onCropImage} imageThumb={imageSrc} />, 'CẮT ẢNH', 60);

    };
    const onCropImage = (blob: Blob, src: string) =>
    {
        const today = new Date();
        const date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
        const time = today.getHours() + '_' + today.getMinutes() + '_' + today.getSeconds();
        const fileName = informationBeingExam?.medicalExaminationId + '_' + date + '_' + time + '.png';
        const fileType = blob.type;
        objectKey = `clinics/${ clinicId?.toString() ?? '000000' }/${ getObjectType(fileType as string) }/${ fileName }`;
        const imageComboNew: InfoFileUploadCombo_v2 = {
            index: (comboActive?.length + 1) as number,
            isTick: false,
            thumbUrl: src,
            thumbUrlCloud: '',
            nameImage: fileName,
            objectKey: objectKey,
            file: blob as RcFile,
            fileType: fileType
        };
        comboActiveRef.current = [
            ...comboActive,
            imageComboNew
        ];
        imageArrCurrent.current = [
            ...comboActive,
            imageComboNew
        ];
        onCloseModal();
        createMultiPartUpload(blob as RcFile, fileName as string, objectKey as string);
        setComboActive([
            ...comboActive,
            imageComboNew
        ]);
    };


    const convertBase64ToFile = function (image: string)
    {
        const byteString = atob(image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1)
        {
            ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
            type: 'image/jpeg',
        });
        return newBlob;
    };

    const onRenderCamera = () =>
    {
        const deviceIdShow = state.deviceId ? state.deviceId : '';
        return <div className='h-full w-full flex flex-col' >
            <div className="flex">
                <ComboBox value={deviceIdShow}
                    onChange={(value) => { onSetDeviceCamera(value); }}
                    comboValue={state.devicesCombo}
                    name={'camera'}
                    label={'Nguồn camera'} />
            </div>

            <div className="mt-2">
                <WebcamCapture imageAdjustment={imageAdjustment}
                    setImageAdjustment={setImageAdjustment}
                    webcamRef={webcamRef}
                    deviceId={deviceIdShow} />
            </div>
            <div className="flex flex-col">
                <div className="w-full flex justify-center items-center" style={{ height: '50px' }}>
                    <ButtonBase onClick={() => { capture(); }}
                        style={{ height: 40, justifyContent: 'center', alignItems: 'center', padding: '0 6px', marginRight: '6px' }}
                        buttonName={'CHỤP ẢNH'}
                        className=''
                        iconCustom={iconMedicine}
                        backgroudColorCustom='#4D9FDA'
                        buttonType="button_custom" />

                    <ButtonBase onClick={() => { cropImage(); }}
                        style={{ height: 40, justifyContent: 'center', alignItems: 'center', padding: '0 6px' }}
                        buttonName={'CẮT ẢNH'}
                        iconCustom={iconCrop}
                        backgroudColorCustom='#ba8621'
                        buttonType="button_custom" />


                </div>
                <div className='flex justify-center' style={{ height: '40px', alignItems: 'end' }}>
                    {renderUpload()}
                </div>
            </div>
        </div>;
    };
    const validateFileType = (
        { type }: UploadFile,
        allowedTypes?: string[]
    ) =>
    {
        if (!allowedTypes)
        {
            return true;
        }

        if (type)
        {
            return allowedTypes.includes(type);
        }
    };
    const uploadImage = async (options: UploadRequestOption<any>) =>
    {
        const today = new Date();
        const date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
        const time = today.getHours() + '_' + today.getMinutes() + '_' + today.getSeconds();
        const { onSuccess, file } = options;
        const fileNew = file as RcFile;
        const nameFile = informationBeingExam?.medicalExaminationId + '_' + date + '_' + time + '_' + fileNew.name;
        const keyType = fileNew.type;
        objectKey = `clinics/${ clinicId?.toString() ?? '000000' }/${ getObjectType(keyType as string) }/${ nameFile }`;

        try
        {
            const imageComboNew: InfoFileUploadCombo_v2 = {
                index: ((comboActiveRef.current ? comboActiveRef.current?.length : 0) + 1) as number,
                isTick: false,
                thumbUrl: '',
                nameImage: nameFile,
                thumbUrlCloud: '',
                file: file as RcFile,
                objectKey: objectKey,
                fileType: keyType,
            };
            const comboActiveArr = [
                ...(comboActiveRef.current ? comboActiveRef.current : []),
                imageComboNew
            ];
            comboActiveRef.current = comboActiveArr;
            imageArrCurrent.current = comboActiveArr;
            overlayRef.current?.open();
            createMultiPartUpload(file as RcFile, nameFile as string, objectKey as string);

        } catch (exception)
        {
            console.log(exception);
        }
        const fmData = new FormData();
        fmData.append('image', file);
        try
        {
            onSuccess?.('Ok');
        } catch (err)
        {
            console.log('Eroor: ', err);
        }
    };

    const handleOnChange = (value: UploadChangeParam<UploadFile<any>>) =>
    {
        if ((value.file.percent as number) === 100)
        {
            onLoadImage(value.file);
            setDefaultFileList(value.fileList);
        }
    };

    const onLoadImage = (file: UploadFile) =>
    {
        const reader = new FileReader();
        reader.onload = async (e) =>
        {
            const imageUrl = e?.target?.result as string;
            const comboActiveNew = _.cloneDeep(comboActiveRef?.current) as InfoFileUploadCombo_v2[];
            const comboActiveSet = comboActiveNew.map(item =>
            {
                if (item.file?.name === file.name)
                {
                    item.thumbUrl = imageUrl;
                    item.fileType = file.type;
                }

                const isAllowedTypeVideo = validateFileType(file, ['video/mp4']);
                if (item.file?.name === file.name && isAllowedTypeVideo)
                {
                    item.isVideo = true;
                }
                return item;
            });
            comboActiveRef.current = comboActiveSet;
            imageArrCurrent.current = comboActiveSet;
            setComboActive(comboActiveSet);
        };
        reader.readAsDataURL(file?.originFileObj as Blob);

    };


    const renderUpload = () =>
    {
        return <Upload
            name="file"
            // accept="image/*"
            customRequest={uploadImage}
            multiple={true}
            beforeUpload={(file: UploadFile) =>
            {
                const isAllowedType = validateFileType(file, ['image/png', 'image/jpeg', 'video/mp4', 'application/pdf']);
                if (!isAllowedType)
                {
                    ApiUtil.ToastError(`${ file.name } không phải video hoặc hình ảnh! Vui lòng chọn lại file đúng định dạng!`);

                    return Upload.LIST_IGNORE;
                }
                return true;
            }}
            onRemove={(file: UploadFile) =>
            {
                if (defaultFileList.some((item) => item.uid === file.uid))
                {
                    setDefaultFileList((fileList) =>
                        fileList.filter((item) => item.uid !== file.uid)
                    );
                    return true;
                }
                return false;
            }}
            showUploadList={false}
            onChange={handleOnChange}
        >
            <a className='underline' style={{ fontSize: '20px' }}>
                hoặc chọn từ máy tính
            </a>
        </Upload>;
    };


    const checkKeyDown = (e: any) =>
    {
        if (e.code === 'Enter' || e.code === 'Space') 
        {
            // onCreate();
            capture();
            e.preventDefault();
        }
    };

    return (
        <div className='w-full h-full'
            style={{ borderColor: 'white' }}
            tabIndex={-1}
            onKeyDown={checkKeyDown}
        >
            <BaseForm ref={null}
                onSubmit={() => { onSubmitAll(); }}
                className='flex'
                // onKey={checkKeyDown}
                style={{ width: '100%', height: '98%' }}
                defaultValues={{}} >
                <div style={{ width: '60%', height: '100%', backgroundColor: '#fff', borderRadius: '7px' }}>
                    <div className="mb-2" style={{ width: '100%', height: 'calc(100% - 60px)', display: 'flex', flexFlow: 'wrap', overflow: 'auto' }}>
                        {comboActive?.map((item, index) =>
                        {
                            return renderUploadCombo(item, index);
                        })}
                    </div>
                    <div className='flex' style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                        <ButtonBase
                            style={{ width: 154, height: 40, justifyContent: 'center' }}
                            className='mr-2'
                            buttonName={'Hoàn tất'}
                            buttonType="save"
                            htmlType="submit" />
                        <ButtonBase className=''
                            onClick={() =>
                            {
                                showConfirm();
                            }}
                            style={{ width: 180, height: 40, justifyContent: 'center' }}
                            buttonName={'Hủy luồng khám'}
                            backgroudColorCustom='#E78429'
                            buttonType="close"
                        />
                    </div>

                </div>
                <div style={{ width: '40%', height: '100%' }}>
                    {onRenderCamera()}
                </div>
            </BaseForm>
            <CustomModal ref={modalRef} />

            <Overlay ref={overlayRef} />

        </div>
    );
};

export default UploadImageView_V2;