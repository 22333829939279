import { Col, Row } from 'antd';
import { useEffect, useRef } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import { Loading } from '../../../components/common/Loading';
import BaseForm from '../../../components/FormFields/BaseForm';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import { loadExamResult } from '../../../features/PatientRecord/api/constants';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { ExamResultModal, InfoPatientFileResulExamDto } from '../../../types/ExamResult/ExamResult';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import HeaderResult from './HeaderResult';
import ResultViewScan from './ResultViewScan';

interface IProps
{
    medicalExaminationId: number,
    datePatient: string
}


interface IState
{
    loading: boolean,
    infoExamResult?: ExamResultModal
}
const ResultView = (props: IProps) =>
{
    const { medicalExaminationId, datePatient } = props;
    const overlayRef = useRef<OverlayRef>(null);
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        loadApi();
    }, []);
    const [state, setState] = useMergeState<IState>({
        loading: true
    });

    const loadApi = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<ExamResultModal>>(BASE_API_PATH + loadExamResult + medicalExaminationId);
        if (response.data.success)
        {
            const data = response.data.results;
            setState({
                loading: false,
                infoExamResult: data as ExamResultModal
            });
        }
    };


    const renderDiseaseList = () =>
    {
        return <div>
            {state.infoExamResult?.diseaseAnalysisResults.map(item =>
            {
                return (<Row key="1"
                    className='mb-4'
                    style={{ width: '100%', marginTop: '-6px' }}>
                    <Col className="flex" style={{ width: '100%', marginTop: 15, padding: 0 }}>
                        <div className='flex font-bold ml-4' style={{ width: '20%' }}>
                            {item?.diseaseAnalysisName} :
                        </div>
                        <div className='flex ml-2' style={{ width: '80%' }}>
                            {item?.content}
                        </div>
                    </Col>
                </Row>);
            })}
        </div>;
    };

    const onRenderImage = () =>
    {
        let indexKey = 0;
        return state.infoExamResult?.imageResults.map(item =>
        {
            const fileType = item.fileType;
            indexKey++;
            return <div key={indexKey} style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ height: '95%', width: '95%' }} >
                    <div className={'rounded-lg'}
                        style={{ width: '100%', aspectRatio: 'auto' }}
                    >
                        <div className="flex h-full w-full" >
                            <div className="flex flex-col h-full w-full" style={{ borderRadius: '8px', justifyContent: 'center' }}>
                                {FileType.Video.includes(fileType ?? '') ?
                                    <video
                                        style={{ height: '100%', width: '100%' }}
                                        className="bg-white border rounded"
                                        controls
                                    >
                                        <source
                                            src={item.thumbUrl}
                                        />
                                    </video>
                                    : (FileType.Pdf.includes(fileType ?? '')) ?
                                        <a href={item.thumbUrl}
                                            target="_blank"
                                            rel="noreferrer"><img
                                                src={pdfIcon}
                                                style={{ height: '50%', width: '50%' }}
                                                className="bg-white border rounded"
                                                alt="..."
                                            /></a>
                                        : <img
                                            src={item.thumbUrl}
                                            style={{ height: '100%', width: '100%' }}
                                            className="bg-white border rounded"
                                            alt="..."
                                        />
                                }
                            </div>
                        </div>
                        <div className='ml-1 font-bold'>
                            {item.name}
                        </div>
                        <div className='ml-1 italic'>
                            Ghi chú: {item.description || 'Không có'}
                        </div>
                    </div>
                </div>
            </div>;
        });

    };

    const renderBaseForm = () =>
    {
        return <BaseForm
            onSubmit={() => { }}
            style={{ width: '100%', height: '97%' }}
            defaultValues={{}} >
            <div
                className='w-full '
                style={{ height: '100%' }}>
                <div className="divide-y-2" style={{ width: '100%', height: '100%', borderRadius: '8px' }}>
                    <div >
                        {renderDiseaseList()}
                    </div>
                    <div style={{ width: '90%' }} className="ml-4">
                        <Col className="mt-4 mb-2">
                            <div className="flex items-center">
                                <div className="h-full font-bold mr-4" style={{ width: '100px' }}>
                                    Lịch tái khám:
                                </div>
                                <div className="h-full" style={{ width: '80%' }}>
                                    <div>
                                        {state.infoExamResult?.reExamDate}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>

                </div>

            </div>

        </BaseForm>;
    };

    if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;

    return (
        <div className='w-full h-full flex flex-col overflow-y-hiden' style={{ alignItems: 'center', justifyContent: 'center' }} >
            <div
                style={{ width: '100%', height: '100%' }}
                className=' flex flex-col flex-1 overflow-x-hidden'>
                <div className='ml-3 mr-3 mb-2' style={{ height: '200px' }}>
                    {<HeaderResult componentRef={componentRef}
                        datePatient={datePatient}
                        isScan={true}
                        infoPatientExam={state.infoExamResult?.infoPatient as InfoPatientFileResulExamDto}
                        note={state.infoExamResult?.note || ''}
                        symptom={state.infoExamResult?.symptom || ''}
                    />}
                </div>
                <div className='ml-3 flex flex-col' style={{}}>
                    <div className=' font-bold text-[#2C999C] ml-3 mt-2 mb-2' style={{ height: '25px', fontSize: '16px' }}>
                        MÔ TẢ KẾT QUẢ KHÁM BỆNH
                    </div>
                    <div className='flex flex-col flex-1 overflow-x-hidden'>
                        <div className='flex flex-col' style={{ width: '100%' }}>
                            {renderBaseForm()}
                        </div>
                        <div className='ml-2 mr-2 pb-3' style={{ width: '100%' }}>
                            <div className=' font-bold text-[#2C999C] mt-2 mb-2' style={{ height: '25px', fontSize: '16px' }}>
                                HÌNH ẢNH KHÁM BỆNH
                            </div>
                            <div style={{ height: '95%', width: '100%' }}>
                                <div className="flex" style={{ height: '100%', width: '100%', flexFlow: 'wrap' }}>
                                    {onRenderImage()}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Overlay ref={overlayRef} />
            </div>

            <div style={{ display: 'none' }}>
                <ResultViewScan componentRef={componentRef}
                    data={state.infoExamResult as ExamResultModal}
                    datePatient={datePatient} />
            </div>

        </div>

    );
};

export default ResultView;