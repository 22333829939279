export const lastAddressCombobox = '/Clinic/get-last-address-combo';
export const comboProvince = '/Clinic/get-province-combo';
export const comboDistrict = '/Clinic/get-district-combo/';
export const comboDistrictPatientCare = '/Clinic/get-district-combo-care/';
export const comboProvincePatientCare = '/Clinic/get-province-combo-care';


export const loadInfomationClinic = '/Clinic/index-custom';
export const updateInfomationClinic = '/Clinic/update-custom';
export const updatePdfClinic = '/Clinic/update-pdf-clinic';

// update Time Work

export const updateTimeWorkClinic = '/Clinic/save-time-work';
export const getTimeWorkClinic = '/Clinic/get-time-work';
//payment Vn
export const getInfoPay = '/PayVnMedic/get-info-pay';

export const createPay = '/PayVnMedic/create-user-pay';
export const plusPayment = '/PayVnMedic/plus-payment';
export const deductPayment = '/PayVnMedic/deduct-payment';

export const deductHistoryPayment = '/PayVnMedic/get-history';

export const historyPaymentUrl = '/PayVnMedic/get-history-pay';



