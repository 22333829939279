import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import RadioField from '../../../components/FormFields/RadioField';
import TextField from '../../../components/FormFields/TextField';
import { optionFilterTime } from '../../../constants/OptionsCombo';
import { pathDateTime } from '../../../constants/SvgIcon';
import StatisticDashboardForm from '../../../module/StatisticDashboard/StatisticDashboardForm';
import { MedicalExaminationFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';
import { StatisticsPatientFile, StatisticsPatientFileDashboard } from '../../../types/StatisticsDashboardView.ts/StatisticsDashboard';

interface IHeaderProps
{
    // onFilter: () => void;
    // onOpenCreate: () => void;

    onChangeFilter: () => Promise<void>;
    // optionsProvince : IComboBox[];
    // optionsDistrict : IComboBox[];
    // options?: IComboBox[];
    // onLoadDataGrid: (value: MedicalExaminationFilterDto) => Promise<void>;
    isNotification?: boolean
    costSms?: number
    formRefFilter: React.RefObject<BaseFormRef>
    defaultValuePatientFile?: StatisticsPatientFileDashboard
}



const StatisticsDashboardHeader = (props: IHeaderProps) =>
{
    const { formRefFilter, onChangeFilter, isNotification, costSms, defaultValuePatientFile } = props;
    // const formRef = useRef<BaseFormRef>(null);
    const modalRef = useRef<ModalRef>(null);

    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(true);

    useEffect(() =>
    {

    }, [costSms]);
    const suffixDateTimeFilter = () =>
    {
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };

    const { RangePicker } = DatePicker;

    const comboSetting = [
        { value: 'day', label: 'Ngày' },
        { value: 'week', label: 'Tuần' },
        { value: 'month', label: 'Tháng' },
        { value: 'year', label: 'Năm' },
    ];
    const iconPrint: React.ReactNode = <CopyOutlined style={{ fontSize: '125%' }} className='mr-1' />;


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

    const onChangeTimeFilter = async (value: string) =>
    {
        if (value === 'day')
        {
            setIsActiveTimeRange(false);
        }
        else
        {
            setIsActiveTimeRange(true);
            await onChangeFilter();

        }

    };

    const onModalStatictis = () =>
    {
        const defaultData = onFormatData();
        modalRef.current?.onOpen(<StatisticDashboardForm
            onCloseModal={onCloseModal}
            defaultvalue={defaultData}
        />, 'Thông tin thống kê', 60);

    };

    const onFormatData = () =>
    {
        let result: StatisticsPatientFile = {
            oldPatientTotal: 0,
            oldPatientReExamTotal: 0,
            newPatientTotal: 0,
            newPatientReExamTotal: 0,
            patientTotal: 0,
            patientReExamTotal: 0,
        };

        Object.entries(defaultValuePatientFile as StatisticsPatientFileDashboard).map(
            ([key, value]) => 
            {
                if (!['patientNoPre', 'patientHasPre'].includes(key))
                {
                    const total = value.reduce((total, currentValue) => total = total + currentValue, 0);
                    result = {
                        ...result,
                        [`${ key }` + 'Total']: total
                    };
                }
            }
        );
        result = {
            ...result,
            patientTotal: result['newPatientTotal'] + result['oldPatientTotal'],
            patientReExamTotal: result['newPatientReExamTotal'] + result['oldPatientReExamTotal']
        };
        return result;

    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    return (
        <div className="mt-3 justify-between">
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>BIỂU ĐỒ HỒ SƠ KHÁM BỆNH</p>
            </div>


            <div className='mt-3'>
                <BaseForm ref={formRefFilter}
                    onSubmit={(value) => { onChangeFilter(); }}
                    style={{ width: '100%' }}
                    defaultValues={{ optionValue: 'month', comboSetting: 'day' }} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px', marginBottom: 16 }}>
                        <Col
                            style={{}}
                            className="">
                            <ComboBox noUpperCaseLabel={true}
                                requiredError={true}
                                onChange={(value) =>
                                {
                                    const dataForm = formRefFilter.current?.getValues();
                                    formRefFilter.current?.setValues({ ...dataForm, optionValue: value });
                                    onChangeTimeFilter(value);

                                }}
                                comboValue={optionFilterTime}
                                name={'optionValue'}
                                label={'Tìm kiếm theo'} />

                        </Col>
                        <Col style={{ width: '20%', marginTop: '24px' }}>
                            <Controller
                                name={'timeRange'}
                                render={({ field }) =>
                                {
                                    return <RangePicker
                                        {...field}
                                        placeholder={['Từ ngày', 'Đến ngày']}
                                        format={dateFormatList}
                                        disabled={isActiveTimeRange}
                                        disabledDate={(current) =>
                                        {
                                            return current && current >= moment().endOf('day');
                                        }}
                                        onChange={
                                            (value) =>
                                            {
                                                const dataForm = formRefFilter.current?.getValues();
                                                formRefFilter.current?.setValues({ ...dataForm, timeRange: value });
                                                if (value)
                                                {
                                                    onChangeFilter();
                                                }
                                            }
                                        }
                                        suffixIcon={suffixDateTimeFilter()}
                                    />;
                                }
                                }
                            />
                        </Col>
                        <div style={{ width: '20%' }}>
                            <ComboBox noUpperCaseLabel={true}
                                requiredError={true}
                                onChange={(value) =>
                                {
                                    const dataForm = formRefFilter.current?.getValues();
                                    formRefFilter.current?.setValues({ ...dataForm, comboSetting: value });
                                    onChangeFilter();
                                }}
                                comboValue={comboSetting}
                                name={'comboSetting'}
                                label={'Chế độ xem'} />
                        </div>

                        {isNotification ? <Col className='mr-2' style={{ width: '10%', display: 'flex', marginTop: '12px', justifyContent: 'end', justifyItems: 'end', flex: 1, paddingRight: 0 }}>
                            <p className='flex text-lg font-bold text-[#2c999c]'>Chi phí SMS : {costSms}</p>
                        </Col> : <>
                        </>}

                        {!isNotification ? <Col className='mr-2' style={{ width: '10%', display: 'flex', marginTop: '12px', justifyContent: 'end', justifyItems: 'end', flex: 1, paddingRight: 0 }}>
                            <ButtonBase className=''
                                onClick={() => { onModalStatictis(); }}
                                style={{ width: 140, height: 40, justifyContent: 'center' }}
                                buttonName={'THỐNG KÊ'}
                                iconCustom={iconPrint}
                                backgroudColorCustom='#4D9FDA'
                                buttonType="button_custom"
                            />
                        </Col> : <>
                        </>}

                    </Row>
                </BaseForm>
            </div>
            <CustomModal ref={modalRef} />

        </div>
    );
};

export default StatisticsDashboardHeader; 