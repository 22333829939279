import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox from '../../../components/FormFields/ComboBox';
import { optionFilterTime } from '../../../constants/OptionsCombo';
import { pathDateTime } from '../../../constants/SvgIcon';

interface IHeaderProps
{
    onChangeFilter: () => Promise<void>;
    formRefFilter: React.RefObject<BaseFormRef>
    totalRevenue?: number;
}



const StatisticDashboardRevenueHeader = (props: IHeaderProps) =>
{
    const { formRefFilter, onChangeFilter, totalRevenue } = props;
    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(true);

    const suffixDateTimeFilter = () =>
    {
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };

    const { RangePicker } = DatePicker;

    const comboSetting = [
        { value: 'day', label: 'Ngày' },
        { value: 'week', label: 'Tuần' },
        { value: 'month', label: 'Tháng' },
        { value: 'year', label: 'Năm' },
    ];

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

    const onChangeTimeFilter = async (value: string) =>
    {
        if (value === 'day')
        {
            setIsActiveTimeRange(false);
        }
        else
        {
            setIsActiveTimeRange(true);
            await onChangeFilter();

        }

    };



    return (
        <div className="mt-3 justify-between">
            <div className='text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>BIỂU ĐỒ DOANH THU</p>
            </div>
            <div className='mt-3'>
                <BaseForm ref={formRefFilter}
                    onSubmit={(value) => { onChangeFilter(); }}
                    style={{ width: '100%' }}
                    defaultValues={{ optionValue: 'month', comboSetting: 'day' }} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px', marginBottom: 16 }}>
                        <Col
                            style={{}}
                            className="">
                            <ComboBox noUpperCaseLabel={true}
                                requiredError={true}
                                onChange={(value) =>
                                {
                                    const dataForm = formRefFilter.current?.getValues();
                                    formRefFilter.current?.setValues({ ...dataForm, optionValue: value });
                                    onChangeTimeFilter(value);

                                }}
                                comboValue={optionFilterTime}
                                name={'optionValue'}
                                label={'Tìm kiếm theo'} />

                        </Col>
                        <Col style={{ width: '20%', marginTop: '24px' }}>
                            <Controller
                                name={'timeRange'}
                                render={({ field }) =>
                                {
                                    return <RangePicker
                                        {...field}
                                        placeholder={['Từ ngày', 'Đến ngày']}
                                        format={dateFormatList}
                                        disabled={isActiveTimeRange}
                                        disabledDate={(current) =>
                                        {
                                            return current && current >= moment().endOf('day');
                                        }}
                                        onChange={
                                            (value) =>
                                            {
                                                const dataForm = formRefFilter.current?.getValues();
                                                formRefFilter.current?.setValues({ ...dataForm, timeRange: value });
                                                if (value)
                                                {
                                                    onChangeFilter();
                                                }
                                            }
                                        }
                                        suffixIcon={suffixDateTimeFilter()}
                                    />;
                                }
                                }
                            />
                        </Col>
                        <div style={{ width: '20%' }}>
                            <ComboBox noUpperCaseLabel={true}
                                requiredError={true}
                                onChange={(value) =>
                                {
                                    const dataForm = formRefFilter.current?.getValues();
                                    formRefFilter.current?.setValues({ ...dataForm, comboSetting: value });
                                    onChangeFilter();
                                }}
                                comboValue={comboSetting}
                                name={'comboSetting'}
                                label={'Chế độ xem'} />
                        </div>
                    </Row>
                </BaseForm>
            </div>
            <div className='text-base font-bold ml-3'>
                Tổng cộng: {totalRevenue?.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }).replace('₫', 'VNĐ')}
            </div>
        </div>
    );
};

export default StatisticDashboardRevenueHeader; 