import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Button, Select, Tabs } from 'antd';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import { Loading } from '../../../components/common/Loading';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { PatientWaitingFilterDto } from '../../../types/PatientWaiting/PatientWaitingFilterDto';
import { PatientWaitingGridDto } from '../../../types/PatientWaiting/PatientWaitingGridDto';
import { StatisticsDashboard, StatisticsRequestDashboard } from '../../../types/StatisticsDashboardView.ts/StatisticsDashboard';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { getClinicCombo, getDataStatisticAdmin } from '../api/constants';
import NotificationDashboardView from './NotificationDashboardAdminView';
import PatientFileDashboardView from './PatientFileDashboardAdminView';
import StatisticsDashboardAdminHeader from './StatisticsDashboardAdminHeader';
import StatisticTodayAdminView from './StatisticTodayAdminView';
import StatisticTodayView from './StatisticTodayAdminView';


const { TabPane } = Tabs;
interface IState
{
    loading: boolean,
    clinicCombo: IComboBox[]
}

const comboSetting = [
    { value: 'day', label: 'Ngày' },
    { value: 'week', label: 'Tuần' },
    { value: 'month', label: 'Tháng' },
    { value: 'year', label: 'Năm' },
];
const StatisticsDashboardAdminView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const [state, setState] = useMergeState<IState>({
        loading: true,
        clinicCombo: []
    });

    const [clinicSelectId, setClinicSelectId] = useState<string[]>(['all']);
    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
        Axios.all([
            await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + getClinicCombo)
        ]).then(axios.spread((resClinicCombo) =>
        {
            let allClinic: IComboBox[] = [];

            if (resClinicCombo.data.success)
            {
                allClinic = resClinicCombo.data.results as IComboBox[];
            }
            setState({
                ...state,
                loading: false,
                clinicCombo: allClinic
            });
        }));
    };


    const onChange = (value: string[]) =>
    {
        setClinicSelectId(value);
    };

    const onSearch = (value: string) =>
    {
    };

    const operations = (): JSX.Element =>
    {
        return <div className='mr-4 flex flex-1'>
            {/* <p className='mr-2 flex items-center justify-center'>
                Phòng khám
            </p> */}
            <div className='flex mr-2 items-center justify-center mt-2'>
                {(
                    <label
                        className={'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                        }
                    >
                        Phòng khám
                    </label>

                )}
            </div>
            <Select
                showSearch
                placeholder="Chọn phòng khám"
                optionFilterProp="children"
                onChange={onChange}
                defaultValue={['all']}
                mode="multiple"
                style={{
                    width: '600px', borderRadius: '7px',
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={state.clinicCombo}
            />

        </div>;
    };


    if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;
    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='flex-1 ml-2 w-full h-full'>
                <Tabs className='w-full h-full' tabBarExtraContent={operations()}>
                    <TabPane
                        tab="Hôm nay"
                        key="1"
                        className='h-full'>
                        <StatisticTodayAdminView clinicId={clinicSelectId} />
                    </TabPane>
                    <TabPane tab="Thông báo"
                        key="2"
                        className='h-full'>
                        <NotificationDashboardView clinicId={clinicSelectId} />
                    </TabPane>
                    <TabPane tab="Hồ sơ khám bệnh"
                        key="3"
                        className='h-full'>
                        <PatientFileDashboardView clinicId={clinicSelectId} />
                    </TabPane>

                </Tabs>
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default StatisticsDashboardAdminView;