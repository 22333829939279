import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import TextAreaField from '../../../components/FormFields/TextAreaField';

interface IHeaderProps
{
    onSendMultiSms: (content: string) => Promise<void>,
    onSendMultiNoti: (content: string) => Promise<void>,
    countSelected: number
}
const schema = yup.object().shape({
    content: yup.string().max(50, 'Vui lòng gửi không quá 50 ký tự!').required('Vui lòng nhập thông tin!'),
});
const PatientSendSmsHeader = (props: IHeaderProps) =>
{
    const { onSendMultiSms, onSendMultiNoti, countSelected } = props;
    const formRef = useRef<BaseFormRef>(null);
    return (
        <div className="mt-3 justify-between ">
            <div className='flex text-xl font-bold ml-3' style={{ marginBottom: -7 }}>
                <p className='mr-2 text-[#2c999c]' style={{ font: 'Roboto', fontSize: 16 }}>CÀI ĐẶT NỘI DUNG GỬI THÔNG BÁO</p>

                <p style={{ font: 'Roboto', fontSize: 16 }}>Số lượng bệnh nhân đã chọn : {countSelected} </p>
            </div>
            <div className="overflow-y-hidden mt-3">
                <BaseForm
                    ref={formRef}
                    onSubmit={() => { onSendMultiSms(formRef.current?.getValues()?.['content'] as string); }}
                    style={{ width: '100%' }}
                    className="flex"
                    resolver={yupResolver(schema)}

                    defaultValues={{}} >

                    <Row className="flex "
                        gutter={30}
                        style={{ width: '87%' }}>
                        <Col span={40} className="flex-1">
                            <Form.Item
                            >
                                <TextAreaField style={{ height: 80, borderRadius: '7px' }} name={'content'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="footer">
                        {/* <ButtonBase style={{ width: 180, height: 35, display: 'flex', alignContent: 'center', justifyContent: 'center' }}
                            buttonName={'GỬI SMS'}
                            buttonType="sms"
                            // disabled={true}
                            htmlType="submit" /> */}
                        <ButtonBase className='mt-2'
                            style={{ width: 180, height: 35, display: 'flex', alignContent: 'center', justifyContent: 'center' }}
                            buttonName={'GỬI THÔNG BÁO'}
                            buttonType="notification"
                            // htmlType="submit"
                            onClick={() => { onSendMultiNoti(formRef.current?.getValues()?.['content'] as string); }}
                        />
                    </div>
                </BaseForm>
            </div>

        </div>
    );
};

export default PatientSendSmsHeader; 