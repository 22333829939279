import 'font-awesome/css/font-awesome.min.css';
import 'antd/dist/antd.min.css';
import './style/variable.scss';
import './index.css';
import './i18n';

import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './saga/store';
import { history } from './utils/History';

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <CookiesProvider>
            {/* <BrowserRouter> */}
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </CookiesProvider>
        {/* </BrowserRouter> */}
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
