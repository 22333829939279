import {ColDef} from 'ag-grid-community';

export const PartnerGridColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 80,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        width: 150,
        tooltipField: 'fullName',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        tooltipField: 'phone',
        headerClass: 'text-center',
        width: 120,
        maxWidth: 120,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Phòng khám phụ trách',
        field: 'clinicName',
        tooltipField: 'clinicName',
        headerClass: 'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Tỉnh',
        field: 'provinceName',
        tooltipField: 'provinceName',
        headerClass: 'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Địa chỉ',
        field: 'address',
        tooltipField: 'address',
        headerClass: 'text-center',
        width: 60,
        cellStyle: { 'text-align': 'start' }
    }
];
