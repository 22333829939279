

import 'ol/ol.css';

import { Button, Modal } from 'antd';
import { Map, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';

import { LocationIcon } from '../../icons/LocationIcon';
import { firstLocation } from './InfomationUpdate';



interface ModalSelectLocationData
{
    onChangeValue?: (value: number[]) => void // hàm để hứng giá trị select khi đã xác nhận giá trị đã chọn
    defaultLocation?: number[] // biến chứa giá trị vị trí đã lưu dưới DB
    openModal: boolean
    setOpenModal: (value: boolean) => void
}

export const ModalSelectLocation: React.FC<ModalSelectLocationData> = ({
    onChangeValue,
    defaultLocation = firstLocation,
    setOpenModal,
    openModal = false
}) =>
{

    const markerRef: any = useRef(null); // Ref để lưu trữ marker
    const mapRef = useRef(null);

    // biến chứa giá trị tung độ, vĩ độ khi click vào 1 điểm trên bản đồ
    const [locationSelected, setLocationSelected] = useState<number[]>(defaultLocation);

    const [locationConfirm, setLocationConfirm] = useState<number[]>([]);


    const handleConfirmModal = () =>
    {
        onChangeValue && onChangeValue(locationSelected);
        setLocationConfirm(locationSelected);

        setTimeout(() =>
        {
            setOpenModal(false);
        }, 300);

    };




    const handleCancelModal = () =>
    {
        if (locationConfirm.length === 0)
        {
            // th1: chọn lần đầu -> hủy -> set về defaultLocation
            setLocationSelected(defaultLocation);

        } else
        {
            // th2: chọn, xác nhận, => chọn vị trí -> hủy -> set về locationSelected
            setLocationSelected(locationConfirm);
        }

        setOpenModal(false);
    };


    const renderMap = useCallback(
        () =>
        {
            if (mapRef.current && markerRef.current)
            {
                const map = new Map({
                    target: mapRef.current, // gán target khi đã xác nhận mapRef.current không phải là null
                    layers: [
                        new TileLayer({
                            source: new OSM(),
                        }),
                    ],

                    view: new View({
                        center: defaultLocation,
                        zoom: 14,
                    })

                });

                // Tạo marker
                const marker = new Overlay({
                    position: defaultLocation,
                    positioning: 'center-center',
                    element: markerRef.current,
                    stopEvent: false,
                });
                map.addOverlay(marker);

                // Lưu trữ marker vào ref để có thể cập nhật tọa độ sau này
                markerRef.current = marker.getElement();

                // Lắng nghe sự kiện click trên bản đồ
                map.on('click', (event) =>
                {
                    const clickedCoordinate = event.coordinate;
                    setLocationSelected(clickedCoordinate);
                    marker.setPosition(clickedCoordinate);
                });



            }

        },
        [openModal],
    );

    useEffect(() =>
    {
        // Kiểm tra xem mapRef.current có phải là null không
        if (!openModal || !mapRef.current) return;

        // Khởi tạo bản đồ OpenLayers
        renderMap();

    }, [openModal]);


    const footerModal = (
        <div className="gap-3 flex justify-end">
            <Button
                className='!bg-[#faad14] !text-white'
                onClick={() =>
                {
                    handleCancelModal();
                }}
            >
                Hủy
            </Button>
            <Button
                className='!bg-[#24afc0] !text-white'
                onClick={() =>
                {
                    handleConfirmModal();
                }}
            >
                Xác nhận
            </Button>
        </div>
    );


    return (
        <Modal
            title='Chọn vị trí'
            open={openModal}
            onCancel={handleCancelModal}
            footer={footerModal}
            width={1000}
        >
            {openModal && <div ref={mapRef} style={{ width: '100%', height: '500px' }} >
                <div ref={markerRef}>
                    <LocationIcon />
                </div>
            </div>}


        </Modal>
    );
};

ModalSelectLocation.propTypes = {
    onChangeValue: PropTypes.func,
};