import { DownloadOutlined, FilePdfOutlined, PrinterOutlined, UploadOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Form, Row, Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import NumberField from '../../components/FormFields/NumberField';
import TextAreaField from '../../components/FormFields/TextAreaField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { phoneRegExp } from '../../constants/YupContants';
import { useDebounce } from '../../hooks/useDebounce';
import { Clinic } from '../../types/Clinic';
import { ApiUtil } from '../../utils/ApiUtil';
import { ModalSelectLocation } from './ModalSelectLocation';
import PrintQR from './PrintQR';

const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
    phone: yup.string().required('Vui lòng nhập thông tin!').min(8, 'Số điện thoại lớn hơn 8 số.').max(12, 'Số điện thoại ít hơn 12 số').matches(phoneRegExp, 'Định dạng không hợp lệ'),
    // districtId: yup.string().required('Vui lòng nhập thông tin!').nullable(),
    // provinceId: yup.string().required('Vui lòng nhập thông tin!').nullable(),
    address: yup.string().required('Vui lòng nhập thông tin!'),
});

export const firstLocation = [11784495.720984768, 2397151.6042929683];
interface IInfomationProps
{
    handleSubmit: (value: Clinic, formData: FormData) => Promise<void>;
    optionsProvince: IComboBox[];
    optionsDistinct: IComboBox[];
    loadOptionsDistrict: (value: string) => void,
    defaultvalue?: Clinic

}


const InfomationUpdate = (props: IInfomationProps) =>
{

    const [defaultFileList, setDefaultFileList] = useState<UploadFile<any> | null>(null);

    const overlayRef = useRef<OverlayRef>(null);
    const { handleSubmit, optionsProvince, optionsDistinct, loadOptionsDistrict, defaultvalue } = props;
    const formRef = useRef<BaseFormRef>(null);
    const componentRef = useRef<HTMLDivElement>(null);
    const [searchAddress, setSearchAddress] = useState('Phường');
    const [openModalLocation, setOpenModalLocation] = useState(false);

    // biến chứa tung độ - vĩ độ được chọn trên bản đồ
    const [locationSelected, setLocationSelected] = useState<number[]>([]);

    const searchAddressText = useDebounce(searchAddress, 400);


    useEffect(() =>
    {
        setDefaultFileList(null);

    }, [defaultvalue]);

    const { data: addressOptions } = useQuery(['getAddressOptions', searchAddressText], async () =>
    {
        const res = await fetch(`https://maps.vietmap.vn/api/search/v3?apikey=ba6f250a9bf1df85dc1cae8ca3f605ad5e9be5c871a83240&text=${ searchAddressText }`).then(res => res.json());
        return res.map((item: { ref_id: string; address: string }) => ({
            value: item.ref_id,
            label: item.address
        }));
    }, {
        initialData: []
    });

    const iconDownload = () =>
    {
        return <DownloadOutlined style={{ fontSize: '150%' }} className="mr-1" />;
    };
    const printDownload = () =>
    {
        return <PrinterOutlined style={{ fontSize: '150%' }} className="mr-1" />;
    };
    const iconUpload = () =>
    {
        return <UploadOutlined style={{ fontSize: '150%' }} className="mr-1" />;
    };

    // const getLocationByRefId = async (refId: string) =>
    // {
    //     return await fetch(`https://maps.vietmap.vn/api/place/v3?apikey=ba6f250a9bf1df85dc1cae8ca3f605ad5e9be5c871a83240&refid=${ refId }`).then(res => res.json());
    // };

    const onSubmit = async (value: Clinic) =>
    {
        const file = defaultFileList ? defaultFileList.originFileObj : null;
        const formData = new FormData();
        formData.append('files', file as Blob);

        // const location = await getLocationByRefId(value.fullAddress as string);

        const params = {
            ...value,
            files: file,
            fullAddress: null,
            // fullAddress: location.display,
            lat: locationSelected?.length > 0 ? String(locationSelected[1]) : null, // tung độ
            lon: locationSelected?.length > 0 ? String(locationSelected[0]) : null // vĩ độ
        };

        // api /clinic/update-custom nhận thêm fullAddress: địa chỉ, lat, lon: toạ độ
        await handleSubmit(params, formData);
        // overlayRef.current?.close();
    };
    const onChangeProvince = (value: string) =>
    {
        const dataForm = formRef.current?.getValues();
        loadOptionsDistrict(value);
        formRef.current?.setValues({ ...dataForm, districtId: '' });
    };
    const downloadQRCode = () =>
    {
        // Generate download with use canvas and stream
        const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
        const pngUrl = canvas?.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${ defaultvalue?.name }.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const printQRCode = () =>
    {

    };

    const validateFileType = (
        { type }: UploadFile,
        allowedTypes?: string[]
    ) =>
    {
        if (!allowedTypes)
        {
            return true;
        }

        if (type)
        {
            return allowedTypes.includes(type);
        }
    };

    const handleOnChange = (value: UploadChangeParam<UploadFile<any>>) =>
    {
        if ((value.file.percent as number) === 100)
        {
            setDefaultFileList(value.file);
        }
    };

    const renderFilePdf = () =>
    {
        return <div className='flex' style={{ height: '100px' }}>
            <div onClick={() => { }}>
                <FilePdfOutlined style={{ fontSize: '80px' }} />
                <p style={{}}>
                    {defaultvalue?.urlPdf && defaultFileList === null ?
                        <a className='text-slate-500 hover:text-blue-600'
                            href={defaultvalue?.urlPdf}
                            download>
                            {defaultvalue?.namePdf}
                        </a>
                        :
                        <p className='text-slate-500'
                        >
                            {defaultFileList?.name}
                        </p>}

                </p>
            </div>
            <div className='w-full ml-10' style={{ alignItems: 'center', display: 'flex' }}>
                <Upload
                    name="file"
                    accept="application/pdf"
                    // customRequest={uploadImage}
                    multiple={true}
                    beforeUpload={(file: UploadFile) =>
                    {
                        const isAllowedType = validateFileType(file, ['application/pdf']);
                        if (!isAllowedType)
                        {
                            ApiUtil.ToastError(`${ file.name } không phải pdf! Vui lòng chọn lại file đúng định dạng!`);

                            return Upload.LIST_IGNORE;
                        }
                        return true;
                    }}
                    onRemove={(file: UploadFile) =>
                    {
                        // if (defaultFileList.some((item) => item.uid === file.uid))
                        if (defaultFileList?.uid === file.uid)
                        {
                            // setDefaultFileList((fileList) =>
                            //     fileList.filter((item) => item.uid !== file.uid)
                            // );
                            setDefaultFileList(null);
                            return true;
                        }
                        return false;
                    }}
                    showUploadList={false}
                    onChange={handleOnChange}
                >
                    <ButtonBase className=''
                        onClick={() =>
                        {
                        }}
                        style={{ width: 120, height: 40, justifyContent: 'center' }}
                        buttonName={'TẢI LÊN'}
                        iconCustom={iconUpload()}
                        backgroudColorCustom='#29cde7'
                        buttonType="button_custom"
                    />
                </Upload>
            </div>
        </div>;
    };

    const renderQrCode = () =>
    {
        return <div className='flex'>
            <QRCode
                value={defaultvalue?.clinicCode ?? 'VNM-00000'}
                style={{ height: '100px', width: '100px' }}
                level={'H'}
                includeMargin={true}
            />
            <div className='ml-4' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <p style={{ fontWeight: 'bolder', fontSize: '14pt' }}>{defaultvalue?.clinicCode ?? 'VNM-00000'}</p>
                {/* <ButtonBase className=''
                    onClick={() =>
                    {
                        downloadQRCode();
                    }}
                    style={{ width: 120, height: 40, justifyContent: 'center' }}
                    buttonName={'TẢI MÃ'}
                    iconCustom={iconDownload()}
                    backgroudColorCustom='#E78429'
                    buttonType="button_custom"
                /> */}
                <ReactToPrint
                    trigger={() => (
                        <ButtonBase className=''
                            onClick={() => { }}
                            style={{ width: 120, height: 40, justifyContent: 'center' }}
                            buttonName={'IN MÃ QR'}
                            iconCustom={printDownload()}
                            backgroudColorCustom='#E78429'
                            buttonType="button_custom" />
                    )}
                    content={() => componentRef?.current != null ? componentRef?.current : null}
                    pageStyle={`
                                @page {
                                    size: A5;
                                    margin: 10mm 5mm;
                                }

                                @page :first {
                                    margin-top: 0;
                                }

                                body {
                                    margin: 0;
                                }

                                .page-break {
                                    page-break-before: always;
                                }
                                `} />
            </div>
            <div style={{ display: 'none' }}>
                <QRCode
                    id='qrcode'
                    value={defaultvalue?.clinicCode ?? 'VNM-00000'}
                    size={800}
                    level={'H'}
                    includeMargin={true}
                />
            </div>
        </div>;
    };

    return ( //onSubmit(value);
        <BaseForm ref={formRef}
            className="p-3"
            defaultValues={defaultvalue}
            resolver={yupResolver(schema)}
            onSubmit={(value) => { onSubmit(value); }} >
            <Row className="flex m-1" gutter={24}>
                <Col span={16} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            name={'name'}
                            requiredError={true}
                            label={'Tên phòng khám'} />
                    </Form.Item>

                </Col>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            name={'phone'}
                            requiredError={true}
                            label={'Số điện thoại'} />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="flex m-1" gutter={24}>
                <Col span={13} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            name={'address'}
                            requiredError={true}
                            label={'Địa chỉ phòng khám'} />
                        {/* <ComboBox
                            onSearch={(e: string) => setSearchAddress(e)}
                            name='fullAddress'
                            label='Địa chỉ phòng khám'
                            comboValue={addressOptions} /> */}
                    </Form.Item>

                </Col>
                <Col span={3} className='!flex items-center justify-end !pr-6'>
                    <Button
                        className='!h-8 !rounded-md'
                        onClick={() =>
                        {
                            setOpenModalLocation(true);
                        }}
                    >Chọn vị trí</Button>
                    {openModalLocation && <ModalSelectLocation
                        openModal={openModalLocation}
                        setOpenModal={setOpenModalLocation}
                        onChangeValue={(value) =>
                        {
                            setLocationSelected(value);
                        }}
                        defaultLocation={
                            locationSelected.length > 0
                                ?
                                locationSelected
                                :
                                [
                                    Number(defaultvalue?.lon) || firstLocation[0],
                                    Number(defaultvalue?.lat) || firstLocation[1]
                                ]}
                    />}

                </Col>
                <Col span={4} className="flex-1">
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            // requiredError={true}
                            onChange={onChangeProvince}
                            comboValue={optionsProvince}
                            name={'provinceId'}
                            label={'Thành phố/Tỉnh'} />

                    </Form.Item>
                </Col>
                <Col span={4} className="flex-1">
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            // requiredError={true}
                            comboValue={optionsDistinct}
                            name={'districtId'}
                            label={'Quận/Huyện'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={16} className="flex-1">
                    <Form.Item>
                        <TextAreaField noUpperCaseLabel={true}
                            style={{ height: 100, borderRadius: '7px' }}
                            name={'introduce'}
                            label={'Giới thiệu về phòng khám'} />
                    </Form.Item>
                </Col>
                <Col span={8} className="flex flex-1">
                    <Form.Item>
                        <label
                            className={
                                'ml-3 block tracking-wide text-gray-700 text-xs font-bold mb-2'
                            }

                        >
                            Mã phòng khám
                        </label>
                        {renderQrCode()}
                    </Form.Item>
                </Col>

            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={16} className="flex-1">
                    <Form.Item>
                        <TextAreaField noUpperCaseLabel={true}
                            style={{ height: 150, borderRadius: '7px' }}
                            name={'notification'}
                            label={'Thông báo của phòng khám'} />
                    </Form.Item>
                </Col>
                <Col span={8} className="flex flex-1">
                    <Form.Item>
                        <label
                            className={
                                'ml-3 block tracking-wide text-gray-700 text-xs font-bold mb-2'
                            }
                        >
                            Bảng giá phòng khám (PDF)
                        </label>
                        {renderFilePdf()}
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'HOÀN TẤT'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />
            <div className='hidden'>
                <PrintQR componentRef={componentRef} clinicInfo={defaultvalue} />
            </div>
        </BaseForm>
    ); //onClose
};

export default InfomationUpdate;